 
import AnchorMenu from '../components/AnchorMenu'
import { client } from '../src/App'
import { Spinner } from '@contentful/f36-spinner'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ProductOwners from '../modules/ProductOwners'
import Faq from '../modules/Faq'
import { dateStringToUSDate, isUpperCase, slugify } from '../functions/utils'
import NewExtensions from '../modules/NewExtensions'
import Ship from '../modules/Ship'
import EntryRequirements from '../modules/EntryRequirements'
import ArrivalInformation from '../modules/ArrivalInformation'
import Hotel from '../modules/Hotel'
import Flights from '../modules/Flights'
import ExpeditionGear from '../modules/ExpeditionGear'
import ActivityOpportunity from '../modules/ActivityOpportunity'
import ExpeditionGuide from '../modules/ExpeditionGuide'
import Marketing from '../modules/Marketing'
import ExtensionOperatingDates from '../modules/ExtensionOperatingDates'
import Itinerary from '../modules/Itinerary'
import ExtensionDetails from '../modules/ExtensionDetails'
import ChartEmbed from '../modules/ChartEmbed'
import Deviations from '../modules/Deviations'

const Content = () => {
  const { id, extensionId } = useParams()
  const [ anchorData, setAnchorData ] = useState([])
  const [ updatedAt, setUpdatedAt ] = useState('')

  const fetcher = async () => {
    try {
      const entryItems = await client.getEntries({
        content_type: id ? 'testProduct' : 'extension', 
        'fields.slug[in]': id || extensionId,
        include: 10
      })
      const item = entryItems.items[0]
      setUpdatedAt(dateStringToUSDate(item.sys.updatedAt))

      const { items } = await client.getEntries({ links_to_entry: item.sys.id, include: 3 })

      const { productOwners, entryRequirements, expeditionGear, FAQS, expeditionGuide, salesMarketing } = items && items.length && items[0].fields
      const { name, deviations, ship, arrivalInformation, hotels, hotelsChartEmbed, flights, flightsChartEmbed, departureInformation, extensions, activityOpportunity, itinerary } = item.fields

      const fieldsObj = {
        name,
        ...(productOwners && { productOwners }),
        ...(itinerary && { itinerary }),
        ...(deviations && { deviations }),
        ...(ship && { ship }),
        ...(entryRequirements && { entryRequirements }),
        ...(arrivalInformation && { arrivalInformation }),
        ...(hotels && { hotels }),
        ...(hotelsChartEmbed && { hotelsChartEmbed }),
        ...(flights && { flights }),
        ...(flightsChartEmbed && { flightsChartEmbed }),
        ...(departureInformation && { departureInformation }),
        ...(expeditionGear && { expeditionGear }),
        ...(extensions && { extensions }),
        ...(activityOpportunity && { activityOpportunity }),
        ...(FAQS && { FAQS }),
        ...(expeditionGuide && { expeditionGuide }),
        ...(salesMarketing && { salesMarketing }),
      }
      const fields = item.sys.contentType.sys.id === 'extension' ? {
        ...(item.fields.productOwners && { productOwners: item.fields.productOwners }),
        ...item.fields,
      } : fieldsObj

      const modules = {
        content: [],
      }
  
      for (let [key, value] of Object.entries(fields)) {
        const subSectionList = ['entryRequirements', 'arrivalInformation', 'departureInformation']
        const subSectionExclusions = ['internalName', 'arrivals', 'inclusions']
        const subSections = []
        if (subSectionList.includes(key)) {
          value && value.forEach(item => {
            Object.keys(item.fields).forEach((subKey, i) => {
              if (!subSectionExclusions.includes(subKey)) {
                if (subKey === 'arrivalInformation') {
                  subSections.push({
                    name: Object.values(item.fields)[i],
                    id: slugify(Object.values(item.fields)[i]),
                  })
                } else {
                  subSections.push({
                    name: isUpperCase(subKey) ? subKey : subKey.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
                    id: subKey === 'additionalInfo' ?  key + subKey : subKey,
                  })
                }
              }
            })
          })
        }
        modules.content.push({
          name: isUpperCase(key) ? key : key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
          id: key,
          subSections,
        })
      }
  
      setData(fields)
      setAnchorData(modules.content)
    } catch (err) {
      console.log(err)
    }
  }

  const [ data, setData ] = useState()
  useEffect(() => {
    fetcher()
    setActiveAnchor('productOwners')
  }, [id, extensionId])

  useEffect(() => {
    if (data) {
      window.scrollTo({ top: 0 })
      document.title = data.name

      const anchorEl = window.location.hash && document.querySelector(window.location.hash)
      if (anchorEl) anchorEl.scrollIntoView()
    }
  }, [data])

  const [ activeAnchor, setActiveAnchor ] = useState('productOwners')

  useEffect(() => {
    if (data) {
      const observerOptions = {
        rootMargin: '50px 0px 50px 0px',
        threshold: [0.1]
      }
      const observer = new IntersectionObserver(observerCallback, observerOptions)
      function observerCallback(entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            //console.log(entry.target.id, entry, window.scrollY)
            if (window.scrollY !== 0) setActiveAnchor(entry.target.id)
          }
        })
      }
      setTimeout(() => {
        const collection = document.querySelectorAll('#moduleSection > article')
        collection.forEach((i) => {
          if (i) {
            observer.observe(i)
          }
        })
      }, 1000)

    }
  }, [data])

  if (!data) return <Spinner size="large" />
  return (
    <>
      <section id="moduleSection" className="module-section">
          <div style={{marginBottom: 40}}>
            <h1 className='content_h1' style={{marginBottom: 15}}>{data.name}</h1>
          </div>
          {data.deviations && <Deviations data={data.deviations} />}
          {data.productOwners && <ProductOwners data={data.productOwners} slug="productOwners" />}
          {data.itinerary && <Itinerary key={data.itinerary.sys.urn} data={data.itinerary.sys.urn} slug="itinerary" />}
          {data.extensionDetails && data.extensionDetails.length && <ExtensionDetails key={data.name} data={data.extensionDetails} slug="extensionDetails" />}
          {(data.extensionOperatingDates || data.extensionOperatingDatesText) && <ExtensionOperatingDates embed={data.extensionOperatingDates} text={data.extensionOperatingDatesText} slug="extensionOperatingDates" />}
          {data.ship && <Ship data={data.ship} slug="ship" />}
          {data.entryRequirements && <EntryRequirements data={data.entryRequirements} slug="entryRequirements" />}
          {data.arrivalInformation && <ArrivalInformation data={data.arrivalInformation} slug="arrivalInformation" />}
          {data.hotels && <Hotel data={data.hotels} slug="hotels" />}
          {data.hotelsChartEmbed && <ChartEmbed data={data.hotelsChartEmbed} slug="hotelsChartEmbed" title="Hotel" />}
          {data.flights && <Flights data={data.flights} slug="flights" />} 
          {data.flightsChartEmbed && <ChartEmbed data={data.flightsChartEmbed} slug="flightsChartEmbed" title="Flights" />} 
          {data.departureInformation && <ArrivalInformation data={data.departureInformation} slug="departureInformation" />} 
          {data.expeditionGear && <ExpeditionGear data={data.expeditionGear} slug="expeditionGear" />}
          {data.extensions && (
            data.extensions.map((ext, i) => {
              if (!ext.fields) return
              const { extension, disclaimer } = ext.fields
              return (
                <div style={{marginTop: 60}} key={i}>
                  <NewExtensions data={extension} disclaimer={disclaimer} slug="extensions" />
                </div>
              )
            })
            
          )}
          {data.activityOpportunity && <ActivityOpportunity data={data.activityOpportunity} slug="activityOpportunity" />}
          {data.FAQS && (
            data.FAQS.map((faq, i) => {
              if (!faq.fields) return
              // TOASK faq.fields.FAQs
              return <Faq key={i} data={faq.fields.questionanswer} slug="FAQS" />
            })
          )}
          {data.expeditionGuide && <ExpeditionGuide data={data.expeditionGuide} slug="expeditionGuide" />}
          {data.salesMarketing && <Marketing data={data.salesMarketing} slug="salesMarketing" />}
      </section>
      <AnchorMenu data={anchorData} activeAnchor={activeAnchor} setActiveAnchor={setActiveAnchor} />
    </>
  )
}

export default Content