import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import EditDate from "../components/EditDate"
import Embed from "../components/Embed"
import { deCamelize, getLastUpdated, getIframeSrcFromString } from "../functions/utils"

const ChartEmbed = ({ data, slug, title }) => {
    const date = getLastUpdated(data)
    const chartEmbedCode = data && data.fields && data.fields.chartEmbed ? documentToPlainTextString(data.fields.chartEmbed) : null
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h2 className="content_h2">{deCamelize(slug.replace(/Embed$/, ''))}</h2>
                <EditDate date={date} />
            </div>
            <div style={{marginTop: 40}}>
                <p className="d-flex-align-center">
                    <b>{title} Chart Link:</b>
                    <a className='link-wrap' href={getIframeSrcFromString(chartEmbedCode)} target="_blank">{getIframeSrcFromString(chartEmbedCode)}</a>
                </p>
                <p><b>{title} Chart:</b></p>
                <Embed data={data.fields.chartEmbed} />
            </div>
        </article>
    )
}

export default ChartEmbed